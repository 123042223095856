/*Match Page CSS*/
.match-list-heading h3 {
  color: #fff;
  text-transform: uppercase;
  font-size: 36px;
  margin-bottom: 30px;
  letter-spacing: 0.75px;
}

.match-list-heading h3 span {
  color: #ff7a21
}

.single-match-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
      align-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  margin-bottom: 15px;
  padding: 20px;
  background: #080811 none repeat scroll 0 0;
  border-radius: 5px;
}

.fag-matchs-list .gamer-image {
  -webkit-transform: perspective(600px) rotateY(0deg) !important;
          transform: perspective(600px) rotateY(0deg) !important;
  background: transparent;
  padding: 0
}

.fag-matchs-list .match-box-middle {
  text-align: center;
  margin: 0 30px;
}

.single-match-list .match-box-left,
.single-match-list .match-box-right {
  max-width: 80px;
}

.matchcountdown h3 {
  font-size: 22px;
  color: #fff;
  text-transform: uppercase;
}

.fag-matchs-list .match-vs {
  width: 18%;
  margin: 15px auto 0;
}

.fag-matchs-list .match-action {
  margin-top: 15px;
}

.match-box-action {
  margin-left: 30px;
}

.match-box-action a.fag-btn {
  background: transparent
}

.match-box-action a.fag-btn:hover {
  background: #ff7a21 none repeat scroll 0 0
}

.single-match-widget {
  text-align: center;
  padding: 30px;
  background: #080811 none repeat scroll 0 0;
  border-radius: 5px;
  margin-bottom: 15px
}
.single-match-widget:last-child{
  margin-bottom: 0
}
.match-widget-inn img {
  width: 50%;
  margin: 20px auto;
}

.single-match-widget h3,
.single-match-widget h4 {
  color: #fff;
  text-transform: uppercase;
}

.single-match-widget h4 span {
  color: #ff7a21
}

.single-match-widget .table-striped > tbody > tr:nth-of-type(2n+1) {
  background-color: #0d0e1b;
  color: #fff;
}
.single-match-widget .table-striped > tbody > tr:nth-of-type(2n+1) td{
  color: #fff;
}
.single-match-widget tbody tr td {
  padding: 25px 20px;
  vertical-align: middle;
}

.single-match-widget table img {
  float: left;
  margin-right: 10px;
  width: 25px;
  margin-top: 0;
  margin-bottom: 0;
}

.single-match-widget table p {
  margin: 0
}

.single-match-widget .table th,
.single-match-widget .table td {
  padding: 12px;
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 0px solid #dee2e6;
  text-align: left;
}

.match-widget-inn .table {
  margin-top: 30px;
  margin-bottom: 0
}

