/*Login Page CSS*/

.login_user {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    top: calc(-100px/2);
    left: calc(50% - 50px);
}

.login-wrapper h3 {
    color: #fff;
    text-transform: capitalize;
    font-size: 28px;
    margin: 20px 0 0;
}

.login-wrapper {
    padding: 2em;
    width: 25em;
    position: relative;
    text-align: center;
}

.login-cta > h2 {
    color: #3e3f5e;
    font-size: 28px;
    font-size: 1.75rem;
    text-align: center;
}

.socials-wrapper > p {
    background: transparent;
    color: #fff;
    font-size: 16px;
    font-size: 1rem;
    position: relative;
    text-align: center;
    z-index: 10;
}

.socials-wrapper > p:after,
.socials-wrapper > p:before {
    background: #d3d3e2;
    content: '';
    display: block;
    height: .10em;
    position: absolute;
    top: 50%;
    -webkit-transform: translatey(-50%);
    transform: translatey(-50%);
    width: 17.5%;
}

.socials-wrapper > p:after {
    right: 0;
}

.socials-wrapper > p:before {
    left: 0;
}

.socials-wrapper > ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    list-style: none;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
        justify-content: center;
    padding: 0;
}

.socials-wrapper > ul > li {
    margin: .5em;
}

.socials-wrapper > ul > li > a {
    -webkit-box-align: center;
    -ms-flex-align: center;
        align-items: center;
    border-radius: .5em;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 2em;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
        justify-content: center;
    text-align: center;
    text-decoration: none;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    width: 2em;
}

.socials-wrapper > ul > li > a.facebook {
    background-color: #3763d2;
}

.socials-wrapper > ul > li > a.twitter {
    background-color: #1abcff;
}

.socials-wrapper > ul > li > a.twitch {
    background-color: #7b5dfa;
}

.socials-wrapper > ul > li > a.youtube {
    background-color: #fd434f;
}

.socials-wrapper > ul > li > a:hover {
    -webkit-transform: translatey(-0.25em);
    transform: translatey(-0.25em);
}

.login-wrapper form {
    margin: 20px 0;
}

.form-row label {
    color: #999 !important
}

.form-row label a {
    color: #fff
}

.login-wrapper form > .form-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: .75em 0;
    position: relative;
}

.login-wrapper form > .form-row > span {
    background: #fff;
    color: #adafca;
    display: inline-block;
    font-weight: 400;
    left: 1em;
    padding: 0 .5em;
    position: absolute;
    pointer-events: none;
    -webkit-transform: translatey(-50%);
    transform: translatey(-50%);
    top: 50%;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.login-wrapper form > .form-row > input,
.login-wrapper form > .form-row > button {
    border-radius: .5em;
    width: 100%;
}

.login-wrapper form > .form-row > input {
    -webkit-transition: 100ms ease all;
    transition: 100ms ease all;
    width: 100%;
    border: medium none;
    border: 1px solid rgba(74, 70, 96, 0.8);
    background: transparent;
    height: 50px;
    padding: 5px 20px;
    color: #fff
}

.login-wrapper form > .form-row > input:-moz-placeholder {
    color: #fff !important;
}

.login-wrapper form > .form-row > input::-moz-placeholder {
    color: #fff !important;
}

.login-wrapper form > .form-row > input:-ms-input-placeholder {
    color: #fff !important;
}

.login-wrapper form > .form-row > input::-webkit-input-placeholder {
    color: #fff !important;
}

.login-wrapper form > .form-row > input:focus {
    border-color: #999;
    outline: none;
}

.login-wrapper form > .form-row > button {
    background-color: #ff7a21;
}

.login-wrapper form > .form-row > button:hover {
    background-color: transparent
}
