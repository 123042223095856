/*Products Page CSS*/
.products-grid .games-thumb {
    background: #080811 none repeat scroll 0 0;
    border-radius: 5px
}

.products-grid .games-single-item {
    margin-bottom: 30px;
}

.products-grid .games-desc h3 {
    margin-bottom: 5px;
    color: #fff;
    font-size: 22px;
    text-transform: capitalize;
    font-weight: 600;
    text-align: center;
}

.products-grid .games-desc h3 a:hover {
    color: #ff7a21
}

.products-grid .games-thumb-image:before {
    display: none
}

.products-grid .games-single-item:after {
    display: none
}
.games-thumb-image{
    text-align: center
}
.games-thumb-image img{
    width: 100%
}
