/*Checkout Page CSS*/

.checkout-left-box h3 {
    margin-bottom: 5px;
    color: #fff;
    font-size: 28px;
    text-transform: capitalize;
    font-weight: 600;
    display: inline-block;
    position: relative;
}

.checkout-left-box form {
    padding: 15px 30px 30px 30px;
    background: #080811 none repeat scroll 0 0;
    border-radius: 30px;
    margin-top: 15px
}

.checkout-left-box form .checkout-form {
    margin-top:30px;
}

.checkout-left-box form label {
    color: #fff;
    text-align: left;
    display: block;
    font-size: 14px;
    font-weight: 500;
}

.checkout-left-box form input {
    width: 100%;
    padding: 5px 15px;
    height: 50px;
    border: medium none;
    border-radius: 3px;
    font-size: 15px;
    background: #0d0e1b;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    color: #fff;
}


.checkout-left-box form textarea {
    width: 100%;
    padding: 5px 15px;
    height: 140px;
    border: medium none;
    border-radius: 3px;
    font-size: 15px;
    background: #0d0e1b;
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    color: #fff;
}
.checkout-left-box form input:-moz-placeholder {
    color: #fff;
}

.checkout-left-box form input::-moz-placeholder {
    color: #fff;
}

.checkout-left-box form input:-ms-input-placeholder {
    color: #fff;
}

.checkout-left-box form input::-webkit-input-placeholder {
    color: #fff;
}
.checkout-left-box form textarea:-moz-placeholder {
    color: #fff;
}

.checkout-left-box form textarea::-moz-placeholder {
    color: #fff;
}

.checkout-left-box form textarea:-ms-input-placeholder {
    color: #fff;
}

.checkout-left-box form textarea::-webkit-input-placeholder {
    color: #fff;
}

.fag-payment .payment {
    display: block;
    position: relative;
    float: left;
    width: 100%;
    height: auto;
}

.fag-payment .payment input[type=radio] {
    position: absolute;
    visibility: hidden;
}

.fag-payment .payment label {
    display: inline-block;
    position: relative;
    padding: 0 0 0 30px;
    margin: 10px auto;
    z-index: 9;
    cursor: pointer;
    -webkit-transition: all 0.25s linear;
    color: #fff;
    font-weight: 500;
    font-size: 15px;
    font-weight: 500;
}

.fag-payment .payment .check {
    display: block;
    position: absolute;
    border: 3px solid #AAAAAA;
    border-radius: 100%;
    height: 20px;
    width: 20px;
    top: 14px;
    left: 0;
    z-index: 5;
    transition: border .25s linear;
    -webkit-transition: border .25s linear;
}

.fag-payment .payment .check:before {
    display: block;
    position: absolute;
    content: '';
    border-radius: 100%;
    height: 8px;
    width: 8px;
    top: 3px;
    left: 3px;
    margin: auto;
    transition: background 0.25s linear;
    -webkit-transition: background 0.25s linear;
}

input[type="radio"]:checked ~ .check {
    border: 3px solid #ff7a21;
}

input[type="radio"]:checked ~ .check:before {
    background: #ff7a21;
}

input[type="radio"]:checked ~ label {
    color: #ff7a21;
}

.payment img {
    float: right;
    margin-top: 15px;
}

.payment p {
    font-size: 15px;
}

.booking-right .action-btn {
    text-align: right;
    margin-top: 30px
}
