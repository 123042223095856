/*Products Page CSS*/

.product-details-image {
    text-align: center;
    background: #080811 none repeat scroll 0 0;
    border-radius: 5px;
    padding: 50px;
}

.product-details-text h3 {
    margin-bottom: 5px;
    color: #fff;
    font-size: 34px;
    text-transform: capitalize;
}

.tour-rating {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.product-details-text .tour-rating ul {
    display: inline-block;
    margin-right: 10px;
    text-align: left
}

.tour-rating ul li {
    display: inline-block;
    font-size: 15px;
    margin: 0;
    line-height: 20px;
}

.tour-rating ul li svg {
    color: #ffa11a;
}

.product-details-text .tour-rating p {
    display: inline-block;
    margin-left: 5px;
    color: #fff;
    text-transform: capitalize;
}

.single-pro-page-para {
    margin: 20px 0;
}

.single-shop-price > p {
    color: #fff;
    font-size: 17px;
    font-weight: 600;
    margin-top: 15px;
    letter-spacing: 0;
}

.single-shop-price p span {
    color: #ff7a21;
    margin-left: 10px;
}


.num-block {
    float: left;
    width: 100%;
    padding: 0;
    margin: 0;
}

.skin-2 .num-in {
    background: #080811 none repeat scroll 0 0;
    border-radius: 25px;
    height: 40px;
    width: 80px;
    float: left;
}

.skin-2 .num-in span {
    width: 40%;
    display: block;
    height: 40px;
    float: left;
    position: relative;
}

.skin-2 .num-in span:before,
.skin-2 .num-in span:after {
    content: '';
    position: absolute;
    background-color: #667780;
    height: 2px;
    width: 10px;
    top: 50%;
    left: 50%;
    margin-top: -1px;
    margin-left: -5px;
}

.skin-2 .num-in span.plus:after {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.skin-2 .num-in input {
    width:100%;
    border-radius: 25px;
    height: 40px;
    border: none;
    text-align: center;
    background: #080811 none repeat scroll 0 0;
    color: #fff
}

.quantity {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
}

.quantity p {
    margin-right: 10px;
    color: #fff;
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 0;
}

.single-shop-page-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 30px;
}

.single-shop-page-btn ul {
    margin-left: 30px;
}

.single-shop-page-btn ul li {
    display: inline-block;
    margin: 0 2px;
}

.single-shop-page-btn ul li a {
    display: block;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 45px;
    border-radius: 50%;
    font-size: 15px;
    background: url(../../img/social_bg_dark.png);
    color: #ff7a21;
}

.single-shop-page-btn ul li a:hover {
    background: url(../../img/social_bg.png);
    color: #fff;
}

.product-panel-list {
    margin-top: 100px;
}

.product-panel-list #pills-tab {
    border-bottom: 1px solid #333;
}

.product-tab li.nav-item {
    margin-right: 15px;
}

.product-panel-list .nav-pills .nav-link.active:after,
.product-panel-list .nav-pills .show > .nav-link:after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    color: #ff7a21;
    height: 2px;
    content: '';
    -webkit-transition: all .25s ease-in-out;
    transition: all .25s ease-in-out;
    background: #ff7a21;
}

.product-panel-list .nav-link:after {
    position: absolute;
    right: auto;
    bottom: 0;
    left: 0;
    display: block;
    width: 0;
    height: 2px;
    content: '';
    -webkit-transition: all .25s ease-in-out;
    transition: all .25s ease-in-out;
    background: #ff7a21;
}

.product-panel-list .nav-pills .nav-link.active,
.product-panel-list .nav-pills .show > .nav-link {
    color: #ff7a21 !important;
    background-color: transparent;
    position: relative;
}

.product-panel-list .nav-pills .nav-link {
    color: #fff;
}

.product-panel-list .nav-link {
    text-transform: uppercase;
    letter-spacing: 0.75px;
    font-weight: 400;
    position: relative;
    padding: 0 0 2px 0;
}

.tab-body h3 {
    margin-bottom: 10px;
    font-size: 28px;
}

.product-panel-list .fag-comment-list {
    width: 75%;
}

.product-panel-list .fag-leave-comment {
    width: 75%;
}
.product-tab {
    margin-top: 30px;
  }
  .product-tab .nav-link{
    background: transparent;
    border: none !important;
    padding: 0 0 2px 0;
    border-radius: 0 !important;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 0.75px;
    font-weight: 400;
    color: #fff;
  }
  .product-tab .nav-link:after {
    position: absolute;
    right: auto;
    bottom: 0;
    left: 0;
    display: block;
    width: 0;
    height: 2px;
    content: '';
    -webkit-transition: all .25s ease-in-out;
    transition: all .25s ease-in-out;
    background: #ff7a21;
  }
  .product-tab .nav-link.active:after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    color: #ff7a21;
    height: 2px;
    content: '';
    -webkit-transition: all .25s ease-in-out;
    transition: all .25s ease-in-out;
    background: #ff7a21;
  }
  .product-tab .nav-item {
    margin-right: 15px;
  }
  .product-tab{
    border-bottom: 1px solid #333 !important;
  }
  .product-tab .nav-link.active {
    color: #ff7a21 !important;
    background: transparent !important;
    position: relative;
  }
  .product-tab .nav-link:hover{
    color: #fff !important;
  }