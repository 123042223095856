/*Game Page CSS*/

.filter_title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: auto;
  width: 100%;
  color: #fff;
  font-size: 20px;
  margin-bottom: 30px;
  letter-spacing: 0.75px;
}

.filter_title button {
  font-size: 14px;
  color: #ff7a21;
  background: transparent;
  border: medium none;
  cursor: pointer;
  text-transform: capitalize;
  font-weight: 600;
  text-decoration: underline;
}

.filter_group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  width: 100%;
  margin-bottom: 25px;
}

.filter_group:last-child {
  margin-bottom: 0
}

.filter_label {
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0;
  color: #dbdada;
  font-weight: normal;
  margin-bottom: 10px;
}

.filter_input {
  background: #080811 none repeat scroll 0 0;
  border: medium none;
  width: 100%;
  height: 45px;
  padding: 5px 15px;
  border-radius: 5px;
  color: #999
}

.filter_input:-moz-placeholder {
  color: #999;
}

.filter_input::-moz-placeholder {
  color: #999;
}

.filter_input:-ms-input-placeholder {
  color: #999;
}

.filter_input::-webkit-input-placeholder {
  color: #999;
}

.filter_select-wrap {
  width: 100%
}

.filter_select {
  width: 100%;
  height: 45px;
  border-radius: 5px;
  border: medium none;
  font-size: 16px;
  color: #999;
  padding: 0 20px;
  cursor: pointer;
  background: url("../../img/arrow2.svg") no-repeat center right 20px #080811;
  background-size: 12px auto;
  letter-spacing: 0.4px;
  width: 100%
}

.filter_range {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #080811;
  font-size: 13px;
  color: #999;
  position: relative;
  margin-bottom: 10px;
  height: 30px;
  padding: 0 12px;
  border-radius: 6px;
  letter-spacing: 0.4px;
}

.filter_range div:first-child {
  margin-right: 16px;
}

.filter_range div {
  position: relative;
}

.filter_range div:first-child:after {
  content: 'â€“';
  position: absolute;
  display: block;
  left: 100%;
  top: 5px;
  color: #fff;
  font-size: 14px;
  margin-left: 4px;
  line-height: 16px;
}

.noUi-horizontal {
  height: 4px;
  margin: 8px 0;
  width: 100%;
}

.noUi-target {
  background: rgba(167, 130, 233, 0.03);
  border-radius: 0;
  border: none;
  box-shadow: none;
}

.noUi-connects {
  background-color: #080811;
  border-radius: 6px;
  border: none;
}

.noUi-connect {
  background-color: #ff7a21;
}

.noUi-handle:before,
.noUi-handle:after {
  display: none;
}

html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
  right: -8px;
}

.noUi-handle.noUi-handle-lower {
  background-color: #ff7a21;
  box-shadow: none;
}

.noUi-handle {
  border: none;
  border-radius: 50%;
  cursor: pointer;
}

.noUi-horizontal .noUi-handle {
  width: 16px;
  height: 16px;
}

.noUi-handle.noUi-handle-upper {
  background-color: #ff7a21;
  box-shadow: none;
}


.custom-checkbox {
  padding-left: 30px;
  position: relative;
  overflow: hidden;
  margin-top: 10px
}

.custom-checkbox input[type="checkbox"] {
  position: absolute;
  left: -100px;
}

.custom-checkbox input[type="checkbox"]:checked + label {
  color: #fff;
}

.custom-checkbox input[type="checkbox"]:checked + label ~ .checkbox {
  background-color: #313148;
}

.custom-checkbox input[type="checkbox"]:checked + label ~ .checkbox:before {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  -webkit-transform: translate(-50%, -50%) scale(1, 1);
  transform: translate(-50%, -50%) scale(1, 1);
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.custom-checkbox label {
  font-size: 16px;
  color: #fff;
  font-weight: normal;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  margin: 0;
}

.custom-checkbox .checkbox {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -10px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: #313148;
}

.custom-checkbox .checkbox:before {
  position: absolute;
  content: "";
  width: 15px;
  height: 15px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23ff7a21' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'%3E%3Cpolyline points='20 6 9 17 4 12'/%3E%3C/svg%3E");
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1.1, 1.1);
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  transition: all 0.3s;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
button.fag-btn {
  background: transparent;
  display: block;
  width: 100%;
  font-size: 18px;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s
}

button.fag-btn:hover {
  background: #ff7a21 none repeat scroll 0 0;
}

.games-category .games-single-item {
  background: #080811 none repeat scroll 0 0;
  margin-bottom: 30px
}



.fag-news-page-area .sidebar-widget {
  margin-top: 50px
}

.news-sidebar-item {
  margin-bottom: 30px;
  padding: 30px;
  border-radius: 18px;
  background: #080811 none repeat scroll 0 0;
}

.news-sidebar-item:last-child {
  margin-bottom: 0
}

.news-sidebar-item form {
  position: relative;
  height: 50px;
  background: #0d0e1b;
  border-radius: 30px;
}

.news-sidebar-item form input {
  position: absolute;
  height: 100%;
  width: 100%;
  background: transparent;
  border: medium none;
  padding: 10px 20px;
  color: #fff
}

.news-sidebar-item form button {
  position: absolute;
  right: 2px;
  height: 100%;
  width: 16%;
  background: #ff7a21;
  color: #fff;
  border-radius: 50%;
  border: 3px solid #0d0e1b;
}

.news-sidebar-item h3 {
  color: #fff;
  font-size: 24px;
  text-transform: uppercase
}

.categories {
  margin-top: 12px;
}

.categories li {
  margin-top: 8px
}

.categories li a {
  color: #999
}

.categories li a svg {
  color: #ff7a21;
  margin-right: 5px
}

.categories li a:hover {
  color: #fff
}

.recent-blog li {
  overflow: hidden;
  padding-left: 0 !important;
  margin-top: 20px;
}

.recent-img {
  margin-right: 10px;
  width: 80px;
  float: left;
}

.recent-text {
  vertical-align: middle;
  overflow: hidden;
}

.recent-text h4 {
  color: #999;
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 4px;
  line-height: 22px;
}

.recent-text h4 a {
  color: #999;
}

.recent-text h4 a:hover {
  color: #fff
}
.related_games{
  padding-bottom: 70px
}
.related_games .games-single-item{
  margin-bottom: 30px
}

.games-category .games-single-item {
  background: #080811 none repeat scroll 0 0;
  margin-bottom: 30px;
}
.pagination-box-row {
  text-align: center;
}

.pagination-box-row p {
  display: inline-block;
  font-size: 16px;
  color: #fff;
  margin-right: 15px;
  margin-bottom: 0
}

.paginations {
  margin-top: 50px;
  text-align: center;
  float: none;
  display: inline-block !important;
}

.paginations li {
  display: inline-block;
  margin-right: 5px
}

.pagination li:last-child {
  margin-right: 0
}

.paginations li a {
  display: block;
  width: 35px;
  height: 35px;
  font-size: 15px;
  line-height: 35px;
  border-radius: 50%;
  color: #fff;
  font-weight: 500;
  background: #080811 none repeat scroll 0 0;
}

.paginations li.active a,
.paginations li a:hover {
  background: #ff7a21 none repeat scroll 0 0;
  color: #fff
}
