/*Video CSS*/
.fag-video-area {
  position: relative
}

.fag-video-inn {
  position: relative;
  width: 70%;
  margin: 0 auto;
  overflow: hidden
}

.fag-video-inn .play-video {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) scale(1);
          transform: translate(-50%, -50%) scale(1);
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-size: 25px;
  background: rgba(246, 139, 31, 0.53);
  border-radius: 50%;
  color: #fff;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  overflow: hidden;
}

.fag-video-inn .play-video svg {
  margin-left: 5px;
  position: relative;
  top: -2px;
}

.fag-video-inn:hover .play-video {
  background: #ff7a21;
  -webkit-transform: translate(-50%, -50%) scale(1.1);
          transform: translate(-50%, -50%) scale(1.1);
}

.zooming {
  animation: zoom 10s ease 0s normal both infinite;
  -webkit-animation: zoom 10s ease 0s normal both infinite;
  -moz-animation: zoom 10s ease 0s normal both infinite;
  -ms-animation: zoom 10s ease 0s normal both infinite;
  -o-animation: zoom 10s ease 0s normal both infinite;
}

@-webkit-keyframes zoom {
  0% {
      -webkit-transform: rotate(0deg) scale(1, 1);
      transform: rotate(0deg) scale(1, 1);
  }

  50% {
      -webkit-transform: rotate(-2deg) scale(1.1, 1.1);
      transform: rotate(-2deg) scale(1.1, 1.1);
  }

  100% {
      -webkit-transform: rotate(0deg) scale(1, 1);
      transform: rotate(0deg) scale(1, 1);
  }
}

@keyframes zoom {
  0% {
      -webkit-transform: rotate(0deg) scale(1, 1);
      transform: rotate(0deg) scale(1, 1);
  }

  50% {
      -webkit-transform: rotate(-2deg) scale(1.1, 1.1);
      transform: rotate(-2deg) scale(1.1, 1.1);
  }

  100% {
      -webkit-transform: rotate(0deg) scale(1, 1);
      transform: rotate(0deg) scale(1, 1);
  }
}


/*Responsive*/
@media(max-width:767px){
  .fag-video-inn {
      width: 100%;
  }
}
@media(min-width: 768px) and (max-width: 991px){
  .fag-video-inn{
    width: 100%
}
}