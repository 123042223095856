/*Cart Page CSS*/

.cart-table-left h3,
.order-summury-box h3 {
    margin-bottom: 5px;
    color: #fff;
    font-size: 28px;
    text-transform: capitalize;
    font-weight: 600;
    display: inline-block;
    position: relative;
}

.cart-table-left thead {
    border: medium none;
}

.cart-table-left thead th {
    border: medium none;
    color: #fff;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 14px;
    border-top: 0px !important
}

.fag-cart-preview {
    width: 115px;
    height: 100px;
    text-align: center;
}

.fag-cart-preview img {
    width: 100%;
    height: 100%;
    border-radius: 15px
}

.table th,
.table td {
    vertical-align: middle;
    border-top: 0px;
    border-top: 1px solid #131324;
}

.cart-table-left table td p {
    color: #fff;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 14px;
    margin: 0
}

.fag-cart-quantity > input {
    border: 2px solid #ddd;
    padding: 5px;
    width: 70px;
    text-align: center;
    border-radius: 25px;
}

.fag-cart-close {
    text-align: center;
}

.fag-cart-total {
    font-weight: 500;
}

.fag-cart-close a {
    color: #fff;
    width: 35px;
    display: inline-block;
    height: 35px;
    line-height: 31px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.cart-clear a {
    display: inline-block;
    font-weight: 600;
    text-transform: capitalize;
    color: #fff;
    margin-right: 15px;
}

.cart-clear {
    margin-top: 20px;
    text-align: right
}

.cart-clear a:last-child {
    margin-right: 1px;
}

.cart-clear a:hover {
    color: #ff7a21
}

.fag-cart-close a:hover {
    background: #ff7a21 none repeat scroll 0 0;
    color: #fff;
    border: 1px solid #ff7a21
}

.summury-inn {
    padding: 20px;
    background: #fff none repeat scroll 0 0;
    border-radius: 30px
}

.order-summury-box table {
    width: 100%;
}

.order-summury-box table tr {
    border-bottom: 1px solid #131324;
}

.order-summury-box table tr:last-child {
    border-bottom: 0px solid #131324;
}

.order-summury-box table td {
    padding: 10px 0;
    color: #fff;
    font-weight: 600;
    font-size: 14px
}

.checkout-action {
    text-align: right;
    margin-top: 30px
}

.checkout-action a.fag-btn {
    color: #fff;
    margin: 0;
    background: transparent
}

.checkout-action a.fag-btn:hover {
    color: #fff;
    background: #ff7a21 none repeat scroll 0 0
}

.cart_box,
.summury-inn {
    background: #080811 none repeat scroll 0 0;
    padding: 20px;
    border-radius: 30px;
    margin-top: 15px;
}

.fag-cart-preview a {
    display: block;
    position: relative;
}

.fag-cart-preview a:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #8f8f8f;
    opacity: 0.1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=10)";
    border-radius: 15px;
}

.fag-cart-quantity .skin-2 .num-in {
    background: #0d0e1b none repeat scroll 0 0;
}

.fag-cart-quantity .skin-2 .num-in input {
    background: #0d0e1b none repeat scroll 0 0;
}

.cart_box .table {
    margin: 0
}