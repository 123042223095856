/*Footer CSS*/
.fag-footer {
  position: relative;
  background: url(../../img/footer_bg.jpg) no-repeat fixed center center/cover;
  z-index: 1
}

.fag-footer:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  left: 0;
  background: #080811 none repeat scroll 0 0;
  opacity: 0.9;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
  z-index: -1;
}

.footer-top-area {
  padding: 70px 0 100px;
  position: relative;
}
.single-footer {
margin-top: 30px;
}

.single-footer h3 {
  color: #fff;
  text-transform: uppercase;
  position: relative;
  font-size: 24px;
  margin-bottom: 20px;
}

.single-footer p {
  color: #727489;
  font-size: 16px;
  margin: 0 0 10px;
}

.single-footer ul li {
  margin: 5px 0 0
}

.single-footer ul li a {
  color: #727489;
  font-size: 15px;
  display: block
}

.single-footer ul li a:hover {
  color: #fff
}

.single-footer ul li a svg {
  margin-right: 5px;
  color: #ff7a21
}

.footer-contact {
  margin-top: 15px;
}

.footer-contact h4 svg {
  width: 25px;
  font-size: 16px;
  color: #ff7a21;
}

.footer-contact h4 {
  font-size: 18px;
}

.footer-contact p {
  margin-left: 25px;
  line-height: 21px;
  margin-top: 5px;
  margin-bottom: 0;
}


.footer-bottom-inn {
  text-align: center;
  padding: 50px 0;
  border-top: 1px solid #181829;
}

.footer-bottom-inn ul {
  margin: 30px 0;
  text-align: center;
}

.footer-bottom-inn ul li {
  display: inline-block;
  margin: 0 2px;
}

.footer-bottom-inn ul li a {
  display: block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  font-size: 15px;
  background: url(../../img/social_bg_hover.png);
  color: #ff7a21;
}

.footer-bottom-inn ul li a:hover {
  background: url(../../img/social_bg.png);
  color: #fff;
}
.copyright p{
  margin: 0
}

/*Responsive*/
@media(max-width:767px){
  .single-footer h3{
    margin-bottom: 10px
  }
}